<template>
  <div id="admin-general" class="admin--page">
    <v-card class="pa-2">
      <v-card-title class="pt-2">Edit Settings</v-card-title>
      <v-card-text>
        <v-form v-if="allSettings">
          <v-jsf v-model="generalSettings" :schema="schema" />
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn @click="updateSettings" :loading="saving">Update General Settings</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="notification.visible"
      :color="notification.color"
      :multi-line="notification.mode === 'multi-line'"
      :timeout="notification.timeout"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large v-if="notification.icon === 'error'">mdi-alert-circle</v-icon>
        <v-icon class="pr-3" dark large v-else-if="notification.icon === 'success'">mdi-check-circle</v-icon>
        <v-icon class="pr-3" dark large v-else-if="notification.icon === 'info'">mdi-information</v-icon>
        <v-layout column>
          <div>
            <strong>{{ notification.title }}</strong>
          </div>
          <div>{{ notification.text }}</div>
        </v-layout>
        <v-btn v-if="notification.timeout === 0" icon @click="notification.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import AdminSetting from '../interfaces/AdminSetting'
import AdminService from '../services/AdminService'

@Component
export default class AdminGeneralSettings extends Vue {
  @Action('admin/fetchAdminSettings')
  private fetchAdminSettings: any

  private allSettings: object | null = null
  private generalSettings: object | null = null
  private saving: boolean = false

  private schema: any = {
    properties: {
      isSkiDataEnabled: {
        type: 'boolean',
        title: 'Display External Order Id As QR Code',
        'x-display': 'switch',
        description: 'Required to print media for SkiData. If not selected, Customer ID will be used.',
      },
      googleAnalyticsTagId: {
        type: 'string',
        default: '',
        title: 'Google analytics tag id',
      },
      resortCodes: {
        type: 'string',
        title: 'Resort codes',
        default: '',
        description: 'Enter one or more resort codes (comma separated)',
      },
      resortGroupCodes: {
        type: 'string',
        title: 'Resort group codes',
        default: '',
        description: 'Enter one or more resort group codes (comma separated)',
      },
      salesLocationIds: {
        type: 'string',
        title: 'Sales location ids',
        default: '',
        description: 'Enter one or more location ids (comma separated)',
      },
      passCheckerLocationId: {
        type: 'string',
        default: '',
        title: 'Pass Checker POS Location Id',
      },
      oneRiskUrl: {
        type: 'string',
        default: '',
        title: '1Risk URL',
      },
      oneRiskSecret: {
        type: 'string',
        default: '',
        title: '1Risk Secret',
      },
      dateFormat: {
        title: '1Risk Date Format',
        type: 'string',
        default: 'mmddyyyy',
        description: 'Preferred Date Format',
        enum: ['mmddyyyy', 'ddmmyyyy'], 
      },
      pointOfSaleSystem: {
        type: 'string',
        default: 'RTP',
        title: 'Point of Sale System',
        description: 'Point of Sale System',
        enum: ['RTP', 'SW'],
      },
      cookieConsentScript: {
        type: 'string',
        default: '',
        title: 'Cookie Consent Script',
      },
      cookieConsentStyles: {
        type: 'string',
        default: '',
        title: 'Cookie Consent Stylesheet',
      },
      displayTasksStartDaysFromProductStart: {
        type: 'number',
        default: 30,
        title: 'Days Before Product Start Date to Display Tasks',
        description: 'Represents the number of days before the product start date to begin displaying tasks',
      },
      displayTasksEndDaysAfterProductStart: {
        type: 'number',
        default: 2,
        title: 'Days After Product Start Date to Display Tasks',
        description: 'Represents the number of days after the product start date to continue displaying tasks',
      },
    },
  }
  private notification: any = {
    visible: false,
    title: '',
    text: '',
    icon: '',
    color: null,
    timeout: 2000,
  }

  public created() {
    this.fetchSettings()
  }

  private async fetchSettings() {
    this.allSettings = await this.fetchAdminSettings()
    this.generalSettings = {
      ...this.allSettings,
    }
  }

  private async updateSettings() {
    if (this.allSettings) {
      this.saving = true
      const newSettings = {
        ...this.allSettings,
        ...this.generalSettings,
      }
      try{
        await AdminService.UpdateAdminSettings(newSettings as AdminSetting)
      }      
      catch(error){
        const message = error?.error?.data?.message
        const notification = {
          title: 'Error',
          text: message,
          icon: 'error',
          color: 'error',
          timeout: 0,
          visible: true,
        }
        this.notification = {
          ...this.notification,
          ...notification,
        }
      }
     
      await this.fetchSettings()
      this.saving = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
